'use client' // Error components must be Client Components

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { useEffect } from 'react'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <main className="min-h-screen flex flex-col items-center justify-center">
      <Card className="max-w-md w-full">
        <CardHeader>
          <h2 className="mt-6 text-center text-3xl font-extrabold">
            Something went wrong.
          </h2>
        </CardHeader>
        <CardContent className="mt-2 space-y-6">
          <div className="flex justify-center space-x-4">
            <Button variant="outline">
              Try again
            </Button>
          </div>
        </CardContent>
        <p className="mt-6 text-center text-base text-gray-500 p-4">
          Need immediate help? Reach out to our
          <a href="https://discord.gg/FpXmvKrNed" className="ml-2 text-primary">Discord</a>
        </p>
      </Card>
    </main >
  )
}
